@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau+Infant:ital,wght@0,1..1000;1,1..1000&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --orange-1: #ffeeef;
  --orange-2: #ffd7d9;
  --orange-3: #ffb8bb;
  --orange-4: #ff969b;
  --orange-5: #ff777d;
  --orange-6: #ff5960;
  --orange-7: #d94c52;
  --orange-8: #b53f44;
  --orange-9: #913337;
  --orange-10: #73282b;
  --gray-1: ##767676;
}

/* Hide the scrollbar track */
::-webkit-scrollbar {
  /* display: none; */
  background-color: transparent;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #73282b; 
  border-radius: 10px; 
}

/* Optionally, style the track (the area where the thumb moves) */
::-webkit-scrollbar-track {
  background-color: #ffeeef; 
}

/* Optionally, style the corner where two scrollbars meet */
::-webkit-scrollbar-corner {
  background-color: #73282b; /* color of the corner */
}

